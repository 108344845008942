<template>
  <main class="page-detail page-detail--news">
    <div class="main-grid">
      <div class="aside">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'home' }">
            <font-awesome-icon :icon="[ 'fas', 'home' ]" />
          </el-breadcrumb-item>
          <el-breadcrumb-item>{{sourceData.title}}</el-breadcrumb-item>
        </el-breadcrumb>
        <section>
          <div class="separate-title">查詢新聞相關分析</div>
          <div class="aside-filter">
            <div class="aside-filter-container">
              <!-- 提及企業品牌 -->
              <div class="aside-filter__box">
                <div class="aside-filter-title">{{NEWS_HIGHTLIGHT_ITEM_DICT.adept_brand}}</div>
                <div class="aside-filter-content">
                  <el-checkbox-group v-model="relatedBrandValue">
                    <el-checkbox
                      class="one-level"
                      :class="colorClass1"
                      :key="item.value"
                      :label="item.value"
                      v-for="item in relatedBrandOptions"
                      @change="onChangeOptionValue">
                      {{item.label}}<div class="count">{{item.count}}</div>
                    </el-checkbox>
                  </el-checkbox-group>
                  <div v-if="!relatedBrandOptions.length">無</div>
                  <!-- <el-button type="text" size="mini">更多</el-button> -->
                </div>
              </div>
              <!-- 提及人物 -->
              <div class="aside-filter__box">
                <div class="aside-filter-title">{{NEWS_HIGHTLIGHT_ITEM_DICT.adept_people}}</div>
                <div class="aside-filter-content">
                  <el-checkbox-group v-model="relatedPeopleValue">
                    <el-checkbox
                      class="one-level"
                      :class="colorClass2"
                      :key="item.value"
                      :label="item.value"
                      v-for="item in relatedPeopleOptions"
                      @change="onChangeOptionValue">
                      {{item.label}}<div class="count">{{item.count}}</div>
                    </el-checkbox>
                  </el-checkbox-group>
                  <div v-if="!relatedPeopleOptions.length">無</div>
                  <!-- <el-button type="text" size="mini">更多</el-button> -->
                </div>
              </div>
              <!-- 提及地點 -->
              <div class="aside-filter__box">
                <div class="aside-filter-title">{{NEWS_HIGHTLIGHT_ITEM_DICT.adept_location}}</div>
                <div class="aside-filter-content">
                  <el-checkbox-group v-model="relatedLocationValue">
                    <el-checkbox
                      class="one-level"
                      :class="colorClass3"
                      :key="item.value"
                      :label="item.value"
                      v-for="item in relatedLocationOptions"
                      @change="onChangeOptionValue">
                      {{item.label}}<div class="count">{{item.count}}</div>
                    </el-checkbox>
                  </el-checkbox-group>
                  <div v-if="!relatedLocationOptions.length">無</div>
                  <!-- <el-button type="text" size="mini">更多</el-button> -->
                </div>
              </div>
              <!-- 提及關鍵字 -->
              <div class="aside-filter__box">
                <div class="aside-filter-title">{{NEWS_HIGHTLIGHT_ITEM_DICT.adept_keyword}}</div>
                <div class="aside-filter-content">
                  <el-checkbox-group 
                  v-model="relatedKeywordValue">
                    <el-checkbox
                      class="one-level"
                      :class="colorClass4"
                      :key="item.value"
                      :label="item.value"
                      v-for="item in relatedKeywordOptions"
                      @change="onChangeOptionValue">
                      {{item.label}}<div class="count">{{item.count}}</div>
                    </el-checkbox>
                  </el-checkbox-group>
                  <div v-if="!relatedKeywordOptions.length">無</div>
                  <!-- <el-button type="text" size="mini">更多</el-button> -->
                </div>
              </div>
            </div>
            <!-- <div class="aside-filter-footer">
              <el-button type="primary">
                <font-awesome-icon :icon="[ 'fas', 'check' ]" />
                醒目標註
              </el-button>
            </div> -->
          </div>
        </section>
      </div>
      <div class="container">
        <h3>{{sourceData.title}}</h3>
        <section>
          <div class="info-list">
            <div class="info-item"><font-awesome-icon :icon="[ 'far', 'newspaper' ]" />{{sourceData.media}}</div>
            <div class="info-item"><font-awesome-icon :icon="[ 'far', 'user' ]" />{{sourceData.author}}</div>
            <div class="info-item"><font-awesome-icon :icon="[ 'far', 'clock' ]" />{{datetimePub}}</div>
          </div>
        </section>
        <section class="article-container">
          <div
            class="section-content article-main"
            v-loading="isLoading">
            <div v-html="contentHtml"></div>
          </div>

          
          <!-- <HighLightContent
            :article="sourceData.content"
            :keywordList="keywordList"
            :colorClasses="colorClasses"
            v-if="sourceData.content">
          </HighLightContent> -->
          <div v-if="sourceData.related&&sourceData.related.length" class="article-aside">
            <h4 class="section-title">相關報導</h4>
            <ul class="related-list">
              <li 
                class="related-item"
                v-for="item in sourceData.related"
                :key="item.uniID">
                <router-link
                  class="related-link"
                  target="_blank"
                  :to="returnLinkTo(item.uniID)">
                  {{item.title}}
                </router-link>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import * as apis from '@/apis/index.ts'
import routerService from '@/utils/routerService.js'
import utils from '@/utils/utils.js'
import * as codes from '@/const/codes.ts'
// import HighLightContent from '@/components/HighLightContent/HighLightContent.vue'


// let article = 'aaa|aa統一超|商1bbbccccc\nccc|商2ccccc統|cc\ncc|一超商3dddddddeeee\neeffffffffgggg\ngghhhhhhiiiiiijj\njjjkkkkkk'

// //let keyword1 = '統一超商股份有限公司'
// let keyword1 = '統一超商'
function replaceText (originArticle, keyword, attrText) {
  let totalMatchCount = 0

  // 頭尾的keyword取代（有換行的keyword）
  let { article, matchCount } = replaceLeftRightText(originArticle, keyword, attrText)
  totalMatchCount = matchCount

  // 完整keyword取代 
  const reg = new RegExp(keyword, 'g')
  const newText = `<span all ${attrText}>${keyword}</span>`
  article = article.replace(reg, newText)

  let textArr = article.split(keyword)
  totalMatchCount = totalMatchCount + textArr.length - 1 // 比對到的關鍵字數量

  return {
    article,
    matchCount: totalMatchCount
  }

  function replaceLeftRightText (article, keyword, attrText) {
    let matchCount = 0 // 比對到的數量
    // 建立所有的關鍵字組合比對清單
    const indexData = returnIndexData(keyword)

    // -- 開始尋找 --
    let lines = article.split('\n')
    lines.forEach((line, lineIndex) => {
      // indexData.forEach(({rightText, rightSelector, rightSelector2, leftText, leftSelector, leftSelector2}, i) => {
      indexData.forEach(({rightText, leftText}, i) => {
        /*
        // -- 尋找右關鍵字 case1 --
        let texts = line.split(rightSelector)
        // 第一行有找到右關鍵字
        if (texts.length > 1) {
          let verticleLineCount = texts[0].split('│').length - 1
          // 非最後一行
          if (lineIndex < lines.length) {
            let nextLine = lines[lineIndex + 1]
            // indexData.forEach(({leftText, leftSelector, leftSelector2}, i) => {
              // -- 尋找左關鍵字 case1 --
              let nextTexts = nextLine.split(leftSelector)
              // 第二行有取到左關鍵字
              if (nextTexts.length > 1) {
                let nextVerticleLineCount = nextTexts[0].split('│').length - 1
                // 第一行和第二行取到的關鍵字左邊的"|"數量一樣//（因第二行關鍵字已包含"|"所以需相差1）
                if (verticleLineCount - nextVerticleLineCount == 1) {
                  // 取代字串
                  lines[lineIndex] = line.replace(rightSelector, `<span ${attrText}>${rightText}</span>│`)
                  lines[lineIndex + 1] = nextLine.replace(leftSelector, `│<span ${attrText}>${leftText}</span>`)
                  // 累計比對數量
                  matchCount++
                }
              }
              // -- 尋找左關鍵字 case2 --
              let nextTexts2 = nextLine.split(leftSelector2)
              // 第二行有取到左關鍵字
              if (nextTexts2.length > 1) {
                let nextVerticleLineCount = nextTexts2[0].split('│').length - 1
                // 第一行和第二行取到的關鍵字左邊的"|"數量一樣//（因第二行關鍵字已包含"|"所以需相差1）
                if (verticleLineCount - nextVerticleLineCount == 1) {
                  // 取代字串
                  lines[lineIndex] = line.replace(rightSelector2, `<span ${attrText}>${rightText}</span>│`)
                  lines[lineIndex + 1] = nextLine.replace(leftSelector2, `│  <span ${attrText}>${leftText}</span>`)
                  // 累計比對數量
                  matchCount++
                }
              }
            // })
          }
        }
        */
        // -- 尋找右關鍵字 case2 --
        let lineTrim = line.trim()
        let cutRightText = lineTrim.substr(lineTrim.length - rightText.length, rightText.length)
        // 第一行有找到右關鍵字
        if (cutRightText === rightText) {
          let nextLine = lines[lineIndex + 1]
          let nextLineTrim = nextLine.trim()
          let cutLiftText = nextLineTrim.substr(0, leftText.length)
          // 第二行有取到左關鍵字
          if (cutLiftText === leftText) {
            // 取代字串
            let indexOfText = line.indexOf(rightText)
            lines[lineIndex] = line.substr(0, indexOfText) + `<span ${attrText}>${rightText}</span>`
            lines[lineIndex + 1] = nextLine.replace(leftText, `<span ${attrText}>${leftText}</span>`)
            // 累計比對數量
            matchCount++
          }
        }
      })
      
    })
    return {
      article: lines.join('\n'),
      matchCount
    }

    // 建立所有的關鍵字組合比對清單
    function returnIndexData (keyword) {
      let indexData = []
      let keywordLength = keyword.length
      let leftCropMaxLength = keywordLength - 1
      for (let n = leftCropMaxLength; n > 0; n--) {
        let rightText = keyword.substring(0, n)
        let leftText = keyword.substring(n, keywordLength)
        indexData.push({
          rightText,
          leftText,
          /*
          rightSelector: rightText + '│', // 右側截取索引
          leftSelector: '│' + leftText, // 左側截取索引
          rightSelector2: rightText + '│', // 右側截取索引
          leftSelector2: '│  ' + leftText // 左側截取索引
          */
          // leftSelector: leftText // 左側截取索引
        })
      }
      return indexData
    }
  }
}

// console.log(newArticle)

export default {
  props: ['uniID'],
  // components: {
  //   HighLightContent
  // },
  data () {
    return {
      NEWS_HIGHTLIGHT_ITEM_DICT: codes.NEWS_HIGHTLIGHT_ITEM_DICT,
      isLoading: true,
      contentHtml: '',
      colorClass1: 'color-primary',
      colorClass2: 'color-danger',
      colorClass3: 'color-warning',
      colorClass4: 'color-success',
      // relatedCompany: [],
      // relatedRepresentative: [],
      // relatedShareHolders: [],
      // relatedManagers: [],
      sourceData: {
        uniID: '',
        sno: 0,
        title: '',
        content: '',
        score: 0,
        author: '',
        media: '',
        datetime_pub: '',
        adept_brand: [],
        adept_people: [],
        adept_location: [],
        adept_keyword: [],
        related: []
      },
      relatedBrandOptions: [],
      relatedPeopleOptions: [],
      relatedLocationOptions: [],
      relatedKeywordOptions: [],
      relatedBrandValue: [],
      relatedPeopleValue: [],
      relatedLocationValue: [],
      relatedKeywordValue: [],
      // keywordList: [],
      // colorClasses: [this.colorClass1, this.colorClass2, this.colorClass3, this.colorClass4]
    }
  },
  computed: {
    ...mapState([
      'originRouteQuery'
    ]),
    datetimePub () {
      if (this.sourceData.datetime_pub) {
        return moment(this.sourceData.datetime_pub).format('YYYY-MM-DD hh:mm:ss')
      } else {
        return '-'
      }
    }
    // contentHtml () {
    //   let content = this.sourceData.content

      

    //   if (this.relatedBrandValue.length) {
    //     for (let i = 0; i < this.relatedBrandValue.length; i++) {
    //       const reg = new RegExp(this.relatedBrandValue[i], 'g')
    //       const newText = `<span class="${this.colorClass1}">${this.relatedBrandValue[i]}</span>`
    //       content = content.replace(reg, newText)

    //       content = replaceLeftRightText(content, this.relatedBrandValue[i], this.colorClass1)
    //     }
    //   }
    //   if (this.relatedPeopleValue.length) {
    //     for (let i = 0; i < this.relatedPeopleValue.length; i++) {
    //       const reg = new RegExp(this.relatedPeopleValue[i], 'g')
    //       const newText = `<span class="${this.colorClass2}">${this.relatedPeopleValue[i]}</span>`
    //       content = content.replace(reg, newText)
          
    //       content = replaceLeftRightText(content, this.relatedPeopleValue[i], this.colorClass2)
    //     }
    //   }
    //   if (this.relatedLocationValue.length) {
    //     for (let i = 0; i < this.relatedLocationValue.length; i++) {
    //       const reg = new RegExp(this.relatedLocationValue[i], 'g')
    //       const newText = `<span class="${this.colorClass3}">${this.relatedLocationValue[i]}</span>`
    //       content = content.replace(reg, newText)

    //       content = replaceLeftRightText(content, this.relatedLocationValue[i], this.colorClass3)
    //     }
    //   }
    //   if (this.relatedKeywordValue.length) {
    //     for (let i = 0; i < this.relatedKeywordValue.length; i++) {
    //       const reg = new RegExp(this.relatedKeywordValue[i], 'g')
    //       const newText = `<span class="${this.colorClass4}">${this.relatedKeywordValue[i]}</span>`
    //       content = content.replace(reg, newText)

    //       content = replaceLeftRightText(content, this.relatedKeywordValue[i], this.colorClass4)
    //     }
    //   }
    //   return content
    // }
  },
  methods: {
    formatDateTime (date) {
      if (date) {
        return moment(date).format('YYYY-MM-DD hh:mm:ss')
      } else {
        return '-'
      }
    },
    returnLinkTo (uniID) {
      return {
        name: 'newsDetail',
        params: { uniID },
        query: this.$route.query
      }
    },
    onChangeOptionValue (isSelect, evt) {
      const key = evt.target.value
      this.toggleHighlight(key, isSelect)
    },
    toggleHighlight (key, isHighlight) {
      const dom = document.querySelectorAll(`[data-value="${key}"]`)
      if (dom) {
        const className = dom[0].getAttribute('data-class')
        if (isHighlight == true) {
          dom.forEach(d => {
            // 開啟
            d.classList.add(className)
            // d.style.cssText = ''
          })
        } else {
          dom.forEach(d => {
            // 關閉
            d.classList.remove(className)
            // d.style.cssText = 'font-family:細明體'
          })
        }
      }
    }
  },
  async created () { 
    if (!this.uniID) {
      // 無查詢條件導回首頁
      this.$router.replace({ name: 'home' })
      return
    }
    // -- 取得新聞資料 --
    const result = await apis.newsGet({
      uniID: this.uniID,
      company_uniID: this.originRouteQuery.company
    })

    this.isLoading = false
    // 無新聞資料
    // if (!result.payload ||
    // !result.payload.found ||
    // !result.payload.source ||
    // !result.payload.source.content) {
    //   this.$message.error('無新聞資料')
    //   return
    // }
    // 取得資料
    let sourceData = result.payload.source
    const companyUniIDKeywords = sourceData.company_uniID_keywords
    // -- 標記關鍵字 --
    let contentHtml = result.payload.source.content
    if (sourceData.adept_brand) {
      let keywords = sourceData.adept_brand
      if (companyUniIDKeywords[0] && keywords.includes(companyUniIDKeywords[0]) === false) {
        keywords = companyUniIDKeywords.concat(keywords)
      }
      let relatedBrandOptions = []
      keywords.forEach((keyword,i) => {
        // 標記關鍵字及取得數量
        const value = `adept_brand__${i}`
        // const attrText = `data-value="${value}" data-class="${this.colorClass1}" class="${this.colorClass1}" `
        const attrText = `data-value="${value}" data-class="${this.colorClass1}" class="" `
        let { article, matchCount } = replaceText(contentHtml, keyword, attrText)
        if (matchCount) {
          // 寫入選單資料
          relatedBrandOptions.push({
            label: keyword,
            value,
            // count: utils.searchKeywordCount(this.sourceData.content, d)
            count: matchCount
          })
          // 取代文章
          contentHtml = article
        }
      })
      this.relatedBrandOptions = relatedBrandOptions
      // this.relatedBrandValue = this.relatedBrandOptions.map(d => d.value)
    }
    if (sourceData.adept_people) {
      let keywords = sourceData.adept_people
      let relatedPeopleOptions = []
      keywords.forEach((keyword,i) => {
        // 標記關鍵字及取得數量
        const value = `adept_people__${i}`
        // const attrText = `data-value="${value}" data-class="${this.colorClass2}" class="${this.colorClass2}"`
        const attrText = `data-value="${value}" data-class="${this.colorClass2}" class=""`
        let { article, matchCount } = replaceText(contentHtml, keyword, attrText)
        if (matchCount) {
          // 寫入選單資料
          relatedPeopleOptions.push({
            label: keyword,
            value,
            // count: utils.searchKeywordCount(this.sourceData.content, d)
            count: matchCount
          })
          // 取代文章
          contentHtml = article
        }
      })
      this.relatedPeopleOptions = relatedPeopleOptions
      // this.relatedPeopleValue = this.relatedPeopleOptions.map(d => d.value)
    }
    if (sourceData.adept_location) {
      let keywords = sourceData.adept_location
      let relatedLocationOptions = []
      keywords.forEach((keyword,i) => {
        // 標記關鍵字及取得數量
        const value = `adept_location__${i}`
        // const attrText = `data-value="${value}" data-class="${this.colorClass3}" class="${this.colorClass3}"`
        const attrText = `data-value="${value}" data-class="${this.colorClass3}" class=""`
        let { article, matchCount } = replaceText(contentHtml, keyword, attrText)
        if (matchCount) {
          // 寫入選單資料
          relatedLocationOptions.push({
            label: keyword,
            value,
            // count: utils.searchKeywordCount(this.sourceData.content, d)
            count: matchCount
          })
          // 取代文章
          contentHtml = article
        }
      })
      this.relatedLocationOptions = relatedLocationOptions
      // this.relatedLocationValue = this.relatedLocationOptions.map(d => d.value)
    }
    if (sourceData.adept_keyword) {
      let keywords = sourceData.adept_keyword
      let relatedKeywordOptions = []
      keywords.forEach((keyword,i) => {
        // 標記關鍵字及取得數量
        const value = `adept_keyword__${i}`
        // const attrText = `data-value="${value}" data-class="${this.colorClass4}" class="${this.colorClass4}"`
        const attrText = `data-value="${value}" data-class="${this.colorClass4}" class=""`
        let { article, matchCount } = replaceText(contentHtml, keyword, attrText)
        if (matchCount) {
          // 寫入選單資料
          relatedKeywordOptions.push({
            label: keyword,
            value,
            // count: utils.searchKeywordCount(this.sourceData.content, d)
            count: matchCount
          })
          // 取代文章
          contentHtml = article
        }
      })
      this.relatedKeywordOptions = relatedKeywordOptions
      // this.relatedKeywordValue = this.relatedKeywordOptions.map(d => d.value)
    }

    this.sourceData = sourceData
    // 寫入html
    this.contentHtml = contentHtml
    
    await this.$nextTick()

    // -- 顯示highlight --

    // 預設「公司名稱」highlight
    if (companyUniIDKeywords[0]) {
      const selfCompany = this.relatedBrandOptions.find(d => {
        return d.label === companyUniIDKeywords[0]
      })
      if (selfCompany) {
        this.toggleHighlight(selfCompany.value, true)
        this.relatedBrandValue = [selfCompany.value]
      }
    }
    // 根據網址參數highhight
    if (this.originRouteQuery.highlight) {
      const lightlightArr = this.originRouteQuery.highlight.split(',')
      this.relatedBrandValue = []
      this.relatedBrandOptions.forEach(d => {
        if (lightlightArr.includes(d.label)) {
          this.toggleHighlight(d.value, true)
          this.relatedBrandValue.push(d.value)
        }
      })
      this.relatedPeopleValue = []
      this.relatedPeopleOptions.forEach(d => {
        if (lightlightArr.includes(d.label)) {
          this.toggleHighlight(d.value, true)
          this.relatedPeopleValue.push(d.value)
        }
      })
      this.relatedLocationValue = []
      this.relatedLocationOptions.forEach(d => {
        if (lightlightArr.includes(d.label)) {
          this.toggleHighlight(d.value, true)
          this.relatedLocationValue.push(d.value)
        }
      })
      this.relatedKeywordValue = []
      this.relatedKeywordOptions.forEach(d => {
        if (lightlightArr.includes(d.label)) {
          this.toggleHighlight(d.value, true)
          this.relatedKeywordValue.push(d.value)
        }
      })
    }

  },
  watch: {
    '$route.query': {
      handler (q) {
        // 如果網址參數是原始參數的話，重新再導向到短網址
        if (this.$route.query.highlight) {
          routerService('replace', {
            name: this.$route.name,
            params: this.$route.params,
            query: this.$route.query
          })
          return
        }
      },
      immediate: true
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/base/_variable.scss';

.article-container {
  // display: flex;
  position: relative;
  max-width: 1440px;

  .article-main {
    // width: 760px;
    // max-width: 760px;

    div {
      max-width: 800px;
      width: 70%;
    }
  }

  .article-aside {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 280px;
    padding-right: 10px;
    @media screen and (max-width: nth($break-point, 4)){
      position: relative;
      margin-top: 80px;
    }
  }
}

// @media screen and (max-width: nth($break-point, 3)){
//   .article-aside {
//     position: relative;
//     margin-top: 80px;
//   }
// }
</style>
